// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-static-aktuelle-spritpreise-benzinpreisfinder-js": () => import("./../../../src/pages/static/aktuelle-spritpreise-benzinpreisfinder.js" /* webpackChunkName: "component---src-pages-static-aktuelle-spritpreise-benzinpreisfinder-js" */),
  "component---src-pages-static-aktueller-benzinpreis-prognose-js": () => import("./../../../src/pages/static/aktueller-benzinpreis-prognose.js" /* webpackChunkName: "component---src-pages-static-aktueller-benzinpreis-prognose-js" */),
  "component---src-pages-static-benzinpreis-heute-js": () => import("./../../../src/pages/static/benzinpreis-heute.js" /* webpackChunkName: "component---src-pages-static-benzinpreis-heute-js" */),
  "component---src-pages-static-benzinpreis-js": () => import("./../../../src/pages/static/benzinpreis.js" /* webpackChunkName: "component---src-pages-static-benzinpreis-js" */),
  "component---src-pages-static-benzinpreis-rechner-js": () => import("./../../../src/pages/static/benzinpreis-rechner.js" /* webpackChunkName: "component---src-pages-static-benzinpreis-rechner-js" */),
  "component---src-pages-static-benzinpreis-verlauf-entwicklung-js": () => import("./../../../src/pages/static/benzinpreis-verlauf-entwicklung.js" /* webpackChunkName: "component---src-pages-static-benzinpreis-verlauf-entwicklung-js" */),
  "component---src-pages-static-benzinpreise-entwicklung-js": () => import("./../../../src/pages/static/benzinpreise-entwicklung.js" /* webpackChunkName: "component---src-pages-static-benzinpreise-entwicklung-js" */),
  "component---src-pages-static-benzinpreise-in-der-umgebung-js": () => import("./../../../src/pages/static/benzinpreise-in-der-umgebung.js" /* webpackChunkName: "component---src-pages-static-benzinpreise-in-der-umgebung-js" */),
  "component---src-pages-static-benzinpreise-vergleichen-js": () => import("./../../../src/pages/static/benzinpreise-vergleichen.js" /* webpackChunkName: "component---src-pages-static-benzinpreise-vergleichen-js" */),
  "component---src-pages-static-guenstige-benzinpreise-finden-js": () => import("./../../../src/pages/static/guenstige-benzinpreise-finden.js" /* webpackChunkName: "component---src-pages-static-guenstige-benzinpreise-finden-js" */),
  "component---src-pages-static-impressum-js": () => import("./../../../src/pages/static/impressum.js" /* webpackChunkName: "component---src-pages-static-impressum-js" */),
  "component---src-pages-static-liter-benzin-aktueller-preis-js": () => import("./../../../src/pages/static/liter-benzin-aktueller-preis.js" /* webpackChunkName: "component---src-pages-static-liter-benzin-aktueller-preis-js" */),
  "component---src-pages-static-warum-gehen-benzinpreise-hoch-js": () => import("./../../../src/pages/static/warum-gehen-benzinpreise-hoch.js" /* webpackChunkName: "component---src-pages-static-warum-gehen-benzinpreise-hoch-js" */),
  "component---src-templates-id-js": () => import("./../../../src/templates/id.js" /* webpackChunkName: "component---src-templates-id-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */)
}

