import red from "@material-ui/core/colors/red"
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: ["Oswald", "sans-serif"].join(","),
    fontSize: 14,
    caption: { fontSize: 12 },
  },
  palette: {
    type: "dark",
    primary: {
      main: "#008298",
      dark: "#1a5970",
      light: "#a9daec",
      contrastText: "#000",
    },
    secondary: {
      main: "#002a35",
      dark: "#001b23",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#001b23",
    },
    text: { primary: "#fff", secondary: "#fff" },
  },
})
theme.typography.h1 = {
  fontSize: 44,
  lineHeight: 1.2,
  [theme.breakpoints.up("md")]: {
    fontSize: 48,
  },
}
theme.typography.h2 = {
  fontSize: 20,
  [theme.breakpoints.up("md")]: {
    fontSize: 34,
  },
}
theme.typography.h3 = {
  fontSize: 16,
  [theme.breakpoints.up("md")]: {
    fontSize: 24,
  },
}
export default theme
